import { React } from "react";
import { Row, Col } from "react-bootstrap";

export const Subnet = (props) => {

  const ipv6Int = () => {

    let words = props.values.toReversed();
    let big6 = words.map(word => BigInt(word)).reduce((accu, word) => {
      return (accu << BigInt(32)) + word;
    });
    return big6.toString();
  }

  if (!props.label) {
    return null;
  }
  let first = 'n/a';
  let last = 'n/a';
  if (props.first) {
    first = props.first.split('/')[0];
    last = props.last.split('/')[0];
  };
  if (first.indexOf('overflow') > 0) {
    last = props.broadcast;
  }
  let classMask = null;
  let smallSize =
    props.size < 2 ** 32 ? (props.ipv6 ? props.size : props.size + 1) : props.size.toExponential(4);
  if (props.clsmap) {
    let classInfo = props.clsmap.split(":");
    classMask = Number(classInfo[1]);
  }
  if (props.err) {
    return <div className="text-err">Error: <span className="value">{props.err}</span></div>
  }
  return (
    <div className="results">
      {props.ipv6 ?
        <Row>
          <Col>
            <div><span className="fw-bold min70">Subnet:</span><span className="value">{props.label}</span></div>
            <div><span className="fw-bold min70">Expanded:</span><span className="value">{props.ipv6Long}</span></div>
            {props.maskVal < 128 ? <div><span className="fw-bold min70">End:</span><span className="value">{props.broadcast}</span></div> :
              <div><span className="fw-bold min70">Address:</span><span className="value">{props.firstHost}</span></div>}
            {props.note && <div><span className="fw-bold min70">IPv4:</span><span className="value">{props.note}</span></div>}
          </Col>
          <Col>
            <div><span className="fw-bold min70">Size:</span><span className="value">{smallSize}</span></div>
            <div><span className="fw-bold min70">n /64 Count:</span><span className="value">{props.slash64Count}</span></div>
            <div><span className="fw-bold min70">Equiv v4 CIDR:</span><span className="value">{props.ipv4Equiv}</span></div>
            <div><span className="fw-bold min70">Integer:</span><span className="value">{ipv6Int()}</span></div>
            {props.usage && <div><span className="fw-bold min70">Useage:</span><span className="value">{props.usage}</span></div>}
          </Col>
        </Row> :
        <Row>
          <Col>
            <div><span className="fw-bold min70">Subnet:</span><span className="value">{props.label}</span></div>
            <div><span className="fw-bold min70">First Host:</span><span className="value">{props.firstHost}</span></div>
            <div><span className="fw-bold min70">Last Host:</span><span className="value">{props.lastHost}</span></div>
            <div><span className="fw-bold min70">Broadcast:</span><span className="value">{props.broadcast}</span></div>
          </Col>
          <Col>
            <div><span className="fw-bold min70">Mask:</span><span className="value">{props.ipv4Mask}</span></div>
            <div><span className="fw-bold min70">Wildcard:</span><span className="value">{props.wildcards}</span></div>
            <div><span className="fw-bold min70">Hex:</span><span className="value">{props.values[0].toString(16)}</span></div>
            <div><span className="fw-bold min70">Int Val:</span><span className="value">{props.values[0]}</span></div>
          </Col>
          <Col>
            <div><span className="fw-bold min70">Size:</span><span className="value">{props.size + 1}</span></div>
            <div><span className="fw-bold min70">Usable:</span><span className="value">{smallSize > 3 ? smallSize - 2 : smallSize}</span></div>
            {props.usage && <div><span className="fw-bold min70">Useage:</span><span className="value">{props.usage}</span></div>}
            {props.offset > 0 && <div><span className="fw-bold min70">Host:</span><span className="value">{props.offset}</span></div>}
            {(props.classful && (props.maskVal > classMask)) ? <>
              <div><span className="fw-bold min70">Borrowed Bits:</span><span className="value">{props.maskVal - classMask}</span></div>
              <div><span className="fw-bold min70">Subnet count:</span><span className="value">{2 ** (props.maskVal - classMask)}</span></div>
            </> : null}
            {props.classful && (props.maskVal < classMask) ?
              <div><span className="fw-bold min70">Super count:</span><span className="value">{2 ** (classMask - props.maskVal)}</span></div>
              : null}
            <div><span className="fw-bold min70">End Int Val:</span><span className="value">{props.v4EndInt}</span></div>
          </Col>
        </Row>
      }

    </div>
  );
}
