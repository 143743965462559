import { useState } from 'react';
import { Form, Row, Col, Table } from "react-bootstrap";

export const TemplateConf = (props) => {
    const [showTemplate, setShowTemplate] = useState(false);

    const toggleTemplate = () => {
        if (showTemplate) {
            setShowTemplate(false);
            props.formik.setFieldValue('template', "");
        } else {
            setShowTemplate(true);
        }
    }

    const toggleOptimize = () => {
        if (props.optimize) {
            props.setOptimize(false);
            props.formik.setFieldValue('optimize', false);
        } else {
            props.setOptimize(true);
            props.formik.setFieldValue('optimize', true);
        }
    }

    let retVal = showTemplate === true ? (
        <Row>
            <Col>
                <Form.Group controlId="template" className="mb-3">
                    <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder={`$\{index}0 permit tcp {acl} any eq 443`}
                        onChange={props.formik.handleChange}
                        onBlur={props.formik.handleBlur}
                        value={props.formik.values.template}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Table size="sm">
                    <tbody style={{ "fontSize": "smaller" }}>
                        <tr><td><span className="code text-bold">Substitution</span></td><td className="text-bold">Description</td><td><span className="code text-bold">Example</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;subnet&#125;</span></td><td>Subnet value with dot mask</td><td><span className="code font-monospace">192.168.1.0 255.255.255.0</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;prefix&#125;</span></td><td>Prefix value with le, ge</td><td><span className="code font-monospace">192.168.1.0/24 le 32</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;cidr&#125;</span></td><td>Subnet with mask length</td><td><span className="code font-monospace">192.168.1.0/24</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;network&#125;</span></td><td>Network value without a mask</td><td><span className="code font-monospace">192.168.1.0</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;acl&#125;</span></td><td>ACL w/ wildcards</td><td><span className="code font-monospace">192.168.1.0 0.0.0.255</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;host&#125;</span></td><td>First IP address on the subnet</td><td><span className="code font-monospace">192.168.1.1</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;index&#125;</span></td><td>Index number. use &#36;&#123;index&#125;0 to get x10s</td><td><span className="code font-monospace">0, 1, 2, etc</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;seq1&#125;</span></td><td>Sequence number, excluding 10s</td><td><span className="code font-monospace">1, 2, .. 9, 11, 12, etc</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;seq1+n&#125;</span></td><td>Multiplier beginning from an offset n, ie: &#36;&#123;seq3+51&#125;</td><td><span className="code font-monospace">51, 54, 57, etc</span></td></tr>   
                        <tr><td><span className="code font-monospace">&#36;&#123;permit:deny&#125;</span></td><td>Define policy verb, ie: &#36;&#123;allow:block&#125;</td><td><span className="code font-monospace">permit-&gt;allow, deny-&gt;block</span></td></tr>
                        <tr><td><span className="code font-monospace">&#36;&#123;tag&#125;</span></td><td>A single word found on the line with a subnet</td><td><span className="code font-monospace">10.20.30.0/24 sales</span></td></tr> 
                    </tbody></Table>
                    <div>Create a text file using substitutions from the data when data has 2 or more rows</div>
            </Col>
        </Row>) : null;


    return <div style={{ "marginTop": "0.5em"}}><Row style={{"marginBottom": "0.5em"}}>
        <Col>
            <Form.Group>
                <Form.Check
                    type="switch"
                    label="Use template"
                    onChange={toggleTemplate}
                />
            </Form.Group>
            {showTemplate === true &&
                <Form.Group>
                    <Form.Check
                        type="switch"
                        label="Optimize"
                        onChange={toggleOptimize}
                        defaultChecked={true}
                    />
                </Form.Group>
            }
        </Col>
    </Row>
        {retVal}
    </div>
}